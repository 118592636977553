.map {
  background-color: #1a2a3b;
  position: relative;
  &__top-shape {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
    width: 100%;
  }
}
