.banner {
  position: relative;
  margin-top: 150px;
  // padding-top: 100px;
  // padding-bottom: 100px;
  @include mq($until: md) {
    z-index: 1;
    // padding-top: 30px;
    // padding-bottom: 0;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 90%;
      width: 100%;
      background: rgb(103,170,213);
      background: linear-gradient(0deg, rgba(103,170,213,1) 0%, rgba(97,167,210,1) 100%);
      z-index: -1;
    }
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 80px;
      bottom: 99%;
      left: 50%;
      transform: translateX(-50%);
      background-image: url("../../images/baner-after-2.png");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 200% auto;
    }
  }
  &__content-wrapper {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    display: grid;
    @include mq($until: md) {
      position: relative;
      top: auto;
      left: 50%;
      transform: translateX(-50%);
      padding-bottom: 70vw;
      padding-right: $padding-lg;
    }
    @include mq($until: sm) {
      padding-right: $padding-sm;
    }
    @include mq($until: xs) {
      padding-right: $padding-xs;
    }
  }
  &__title {
    display: block;
    text-align: left;
    color: $color-extra;
    font-size: rem(60px);
    font-family: $font-family-extra;
    text-transform: uppercase;
    margin-bottom: 0;
    @include mq($until: lg) {
      font-size: rem(50px);
    }
    @include mq($until: md) {
      text-align: center;
      font-size: rem(55px);
    }
    span {
      font-size: inherit;
      font-family: inherit;
      font-weight: inherit;
      color: $color-main;
      text-transform: uppercase;
      display: inline-flex;
      span {
        font-size: rem(30px);
        @include mq($until: lg) {
          font-size: rem(20px);
        }
      }
    }
  }
  &__subtitle {
    font-family: $font-family-extra;
    display: block;
    margin-bottom: 0;
    font-size: rem(60px);
    line-height: rem(65px);
    text-align: center;
    color: $color-extra;
    font-weight: $fw-light;
    text-transform: uppercase;
    @include mq($until: lg) {
      font-size: rem(50px);
      line-height: rem(50px);
    }
  }
  &__awards {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: md) {
      margin-top: 20px;
      flex-direction: column;
    }
  }
  &__award-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px;
    min-width: 345px;
    &:first-child {
      margin-left: 0;
    }
    @include mq($until: lg) {
      min-width: 284px;
    }
    @include mq($until: md) {
      margin: 0;
      min-width: 100%;
    }
  }
  &__award-counter {
    height: 60px;
    display: flex;
    width: 60px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 100%;
    color: $color-extra;
    font-family: $font-family-extra;
    text-transform: uppercase;
    font-size: rem(28px);
    font-weight: $fw-bold;
    margin-right: 5px;
    @include mq($until: lg) {
      font-size: rem(22px);
      height: 45px;
      width: 45px;
    }
    @include mq($until: md) {
      font-size: rem(30px);
      margin-right: 10px;
      height: 50px;
      width: 50px;
    }
  }
  &__award-amount {
    font-family: $font-family-extra;
    font-size: rem(80px);
    font-weight: 900;
    color: $color-main;
    text-shadow: -2px 0px #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
    span{
      font-size: inherit;
      color: inherit;
      font-weight: inherit;
      font-family: inherit;
    }
    @include mq($until: lg) {
      font-size: rem(65px);
    }
    @include mq($until: md) {
      font-size: rem(80px);
    }
    &--secondary {
      color: $color-extra;
    }
  }
  &__buttons-wrapper {
    margin-top: 120px;
    @include mq($until: xxl) {
      margin-top: 90px;
    }
    @include mq($until: xl) {
      margin-top: 60px;
    }
    @include mq($until: lg) {
      margin-top: 40px;
    }
    @include mq($until: md) {
      position: absolute;
      bottom: 40px;
      left: 50%;
      margin: 0;
      transform: translateX(-50%);
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-left: $padding-lg;
      padding-right: $padding-lg;
    }
    @include mq($until: sm) {
      padding-left: $padding-sm;
      padding-right: $padding-sm;
      bottom: 30px;
    }
    @include mq($until: xs) {
      grid-template-columns: 1fr;
      padding-left: $padding-xs;
      padding-right: $padding-xs;
      position: relative;
      left: auto;
      bottom: auto;
      transform: none;
      margin-top: 20px;
    }

    .button {
      margin-left: 20px;
      &:first-child {
        margin-left: 0;
      }
      @include mq($until: md) {
        margin-left: 0;
        min-width: unset;
      }
    }
  }
  &__bg {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    @include mq($until: xl) {
      left: auto;
      right: 0;
    }
    @include mq($until: md) {
      display: none;
    }
  }
  &__bg-mobile {
    display: none;
    @include mq($until: md) {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../images/banere-butelka.jpg");
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 150% auto;
    }
  }
}
