#wrap404{

    padding-top: 100px;
    padding-bottom: 100px;
    #ups404{
        font-weight: 900;
        color: #0e0e0e;
        font-size: 45px;
        margin-top: 100px;
        margin-bottom: 50px;
    }
    #txt404{
        font-weight: 300;
        color: #0e0e0e;
        font-size: 24px;
        margin-bottom: 60px;
    }
    #btn404{
        padding: 15px 30px;
        background: #ba292e;
        color: white;
        border-radius: 30px;
        font-weight: 600;
        transition: background-color .3s ease-in-out;
        font-size: 16px;
        &:hover{
            background: #d83136;
        }
    }

}
@media screen and (max-width: 1919px) {}
@media screen and (max-width: 1499px) {
    #wrap404 {
        padding: 100px 30px 100px 30px;
    }
    #wrap404 #ups404 {
        font-size: 35px;
        margin-top: 50px;
        margin-bottom: 30px;
    }
    #wrap404 #txt404 {    font-size: 21px;}
}
@media screen and (max-width: 1199px) {
    #gif404 {
        margin-top: 100px;
    }
}
@media screen and (max-width: 991px) {}
@media screen and (max-width: 767px) {}
@media screen and (max-width: 575px) {
    #wrap404 {
        padding: 30px 0;
        #btn404{
            padding: 8px 16px;
            font-size: 12px;
        }
        #txt404 {font-size: 15px;     margin-bottom: 30px;}
        #ups404 {font-size: 25px;     margin-bottom: 20px;}
    }
    #gif404 {
        margin-top: 50px;
    }
}