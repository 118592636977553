.summary{
    padding-bottom: 140px;
    &__heading {
        margin-bottom: 40px;
        text-transform: uppercase;
        font-family: $font-family-extra;
        color: $color-extra;
        font-size: rem(40px);
      }
    &__images-wrapper{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    }
}