.awards {
  &__bottom-wrapper {
    position: relative;
  }

  &__summary {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    // position: absolute;
    // top: 0;
    // // transform: translateY(-50%);
    width: 100%;
    @include mq($until: md) {
      grid-row-gap: 60px;
      grid-template-columns: repeat(2, 1fr);
    }
    @include mq($until: sm) {
      grid-row-gap: 40px;
    }
    @include mq($until: xs) {
      grid-template-columns: 1fr;
      grid-row-gap: 50px;
    }
  }
  &__counter-wrapper {
    position: relative;
    display: grid;
    grid-template-rows: 30px auto;
    @include mq($until: sm) {
      grid-template-rows: 15px auto;
    }
    @include mq($until: xs) {
      grid-template-rows: 25px auto;
    }
  }
  &__counter {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-family: $font-family-extra;
    font-size: rem(60px);
    line-height: rem(70px);
    font-weight: $fw-bold;
    color: $color-extra;
    white-space: nowrap;
    text-shadow: -2px 0px #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff;
    @include mq($until: xxl) {
      font-size: rem(50px);
      line-height: rem(70px);
    }
    @include mq($until: lg) {
      font-size: rem(40px);
      line-height: rem(80px);
    }
    @include mq($until: md) {
      font-size: rem(60px);
      line-height: rem(90px);
    }
    @include mq($until: sm) {
      font-size: rem(50px);
      line-height: rem(60px);
    }
    @include mq($until: xs) {
      font-size: rem(60px);
      line-height: rem(70px);
    }
  }
  &__amount {
    color: $color-font-light;
    font-size: rem(60px);
    line-height: rem(65px);
    font-family: $font-family-extra;
    font-weight: $fw-bold;
    margin-top: 10px;
    span {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      line-height: inherit;
    }
    @include mq($until: xl) {
      font-size: rem(50px);
      line-height: rem(55px);
    }
    @include mq($until: lg) {
      font-size: rem(45px);
      line-height: rem(50px);
    }
    @include mq($until: xs) {
      font-size: rem(50px);
      line-height: rem(55px);
    }
  }
  &__summary-item {
    display: grid;
    justify-content: center;
    align-items: center;
    background-color: $color-extra2;
    padding: 15px 30px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    @include mq($until: xxl) {
      padding: 15px;
    }
    @include mq($until: xs) {
      padding: 10px;
    }
  }
  &__date-wrapper {
    padding-top: 80px;
    padding-bottom: 100px;
    display: flex;
    max-width: 1920px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    @include mq($until: md) {
      flex-direction: column;
    }
  }
  &__date-item {
    display: grid;
    grid-gap: 15px;
    justify-content: center;
    align-items: center;
  }
  &__date {
    color: $color-font-light;
    font-family: $font-family-extra;
    font-weight: $fw-regular;
    font-size: rem(50px);
    line-height: rem(55px);
    text-transform: uppercase;
    text-align: center;
    @include mq($until: lg) {
      font-size: rem(45px);
      line-height: rem(50px);
    }
    @include mq($until: md) {
      font-size: rem(55px);
      line-height: rem(60px);
    }
    @include mq($until: xs) {
      font-size: rem(50px);
      line-height: rem(55px);
    }
  }
  &__date-label {
    color: $color-font-light;
    text-align: center;
    font-size: rem(20px);
    @include mq($until: lg) {
      font-size: rem(18px);
    }
    @include mq($until: md) {
      font-size: rem(24px);
    }
    @include mq($until: xs) {
      font-size: rem(22px);
    }
  }
  &__separator {
    color: $color-extra;
    font-size: rem(80px);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-family-extra;
    font-weight: $fw-bold;
    @include mq($until: md) {
      transform: rotate(90deg);
      font-size: rem(100px);
    }
    @include mq($until: xs) {
      font-size: rem(80px);
    }
  }
  &__bg-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    background-color: $color-main;
    overflow: hidden;
    z-index: -1;
    @include mq($until: md) {
      height: calc(100% - 55px);
    }
  }
  &__shape {
    position: relative;
    width: 2400px;
    // height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: bottom center;
  }
  &__part1 {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(166, 37, 54, 1) 2%,
      rgba(255, 255, 255, 1) 29%
    );
  }
  &__part2 {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(13, 38, 56, 1) 2%,
      rgba(166, 37, 54, 1) 29%
    );
  }
}
