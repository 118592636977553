.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  // overflow: hidden;
  height: 150px;
  width: 100%;
  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;
  z-index: 1;
  // &:before{
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 3px;
  //   height: 100%;
  //   background-color: $color-main;
  // }
  @include mq($until: xl) {
    height: 10vw;
  }
  @include mq($until: md) {
    height: 100px;
    overflow: visible;
  }
  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color 0.3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }
  // @media (min-width: 2520px){

  // }

  &__logo {
    position: relative;
    z-index: 5;
    max-width: 120px;
    max-height: 90%;
    min-width: 100px;
  }

  &__nav {
    display: flex;
    @include mq($until: md) {
      display: grid;
      align-content: center;
    }
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__nav-media {
    display: none;
    margin: 25px 0;
    @include mq($until: md) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    .user-account__item{
      svg{
        fill: $color-font-light;
      }
      @include mq($until: md) {
        margin-top: 0;
        margin-bottom: 25px;
      }
    }
  }

  &__media {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: md) {
      display: none;
    }
    margin-left: 50px;
    @include mq($until: xxl) {
      margin-left: 30px;
    }
    @include mq($until: xl) {
      margin-left: 20px;
    }
    @include mq($until: lg) {
      margin-left: 15px;
    }
  }

  &__media-item {
    margin-left: 10px;
    margin-top: 0;
    @include mq($until: lg) {
      margin-left: 5px;
    }
    &:first-child {
      margin-left: 0;
      padding-left: 0;
    }
    &:before {
      display: none;
    }
  }

  &__media-link {
    svg {
      fill: $color-font-light;
      width: 36px;
      @include mq($until: xxl) {
        width: 32px;
      }
      @include mq($until: lg) {
        width: 30px;
      }
      @include mq($until: md) {
        width: 36px;
      }
      &:hover {
        fill: $color-extra;
      }
    }
  }

  &__menuList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin-top: 0;
    padding: 0;
    margin-left: 50px;
    @include mq($until: xxl) {
      margin-left: 30px;
    }
    @include mq($until: xl) {
      margin-left: 20px;
    }
    @include mq($until: lg) {
      margin-left: 15px;
    }
    @include mq($until: md) {
      text-align: center;
    }
    &:first-child {
      margin-left: 0;
    }
    &--fullscreen {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: $color-font-light;
    text-transform: uppercase;
    font-weight: $fw-semi-bold;
    font-family: $font-family-extra;
    line-height: rem(33px);
    font-size: rem(24px);
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: xxl) {
      font-size: rem(22px);
    }
    @include mq($until: xl) {
      font-size: rem(20px);
    }
    @include mq($until: lg) {
      font-size: rem(20px);
    }
    @include mq($until: md) {
      font-size: rem(28px);
    }
    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active,
    &--active,
    &--open,
    &:hover {
      color: $color-extra;
      svg {
        fill: $color-extra;
      }
    }
    svg{
      margin-right: 5px;
    }
    &.button {
      padding: 8px 18px;
      align-self: center;
      @include mq($until: xl) {
        padding: 5px 10px;
      }
      @include mq($until: md) {
        padding: 8px 18px;
      }
    }

    &.user-account__item{
      @include mq($until: md) {
        width: 100%;        
      }
    }

  }

  .top-bar {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    width: 101%;
    height: auto;
    @include mq($until: xxl) {
      top: 0;
      bottom: auto;
    }
    @include mq($until: md) {
      height: 100%;
      width: auto;
    }
  }
}

