.home-about {
  padding-top: 120px;
  position: relative;
  padding-bottom: 160px;
  display: grid;
  grid-gap: 50px;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  @include mq($until: xl) {
    // grid-template-columns: repeat(2, 1fr);
  }
  @include mq($until: md) {
    grid-template-columns: 1fr;
  }
  &__heading {
    margin-bottom: 40px;
    text-transform: uppercase;
    font-family: $font-family-extra;
    color: $color-extra;
    font-size: rem(40px);
  }
  &__subtitle {
    margin-bottom: 0;
    text-transform: uppercase;
    color: $color-extra2;
    font-family: $font-family-extra;
    font-size: rem($header-size / 1.5);
    margin-top: 0;
    span {
      color: $color-main;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      display: inline-flex;
      span {
        font-size: rem($header-size / 2);
      }
    }
  }
  &__content {
    font-weight: $fw-semi-bold;
    text-align: center;
  }
  &__icons-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    justify-content: space-between;
    align-items: center;
    @include mq($until: sm) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    svg {
      width: 75px;
      fill: #737373;
    }
  }
}
