.aside{
    &__heading{
        color: $color-extra;
        text-transform: uppercase;
        font-weight: $fw-semi-bold;
        font-family: $font-family-extra;
        font-size: rem(35px);
        margin-bottom: 40px;
    }
    &__news-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @include mq($until: md) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
    &__news-item{
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        @include mq($until: md) {
            order: 1;
            flex-basis: 50%;
            margin-bottom: 20px;
            padding-left: 10px;
            padding-right: 10px;
        }
        @include mq($until: xs) {
            flex-basis: 100%;
            padding-right: 0;
            padding-left: 0;
        }
        &:last-child{
            margin-bottom: 0;
        }
        img{
            transition: transform .3s;
        }
        &:hover img{
            transform: scale(1.01);
        }
        &--decorator{
            max-width: 100%;
            img{
                @include mq($until: md) {
                    max-width: 80%;
                }
                @include mq($until: xs) {
                    max-width: 50%;
                }
            }
            &:hover img{
                transform: none;
            }
            @include mq($until: md) {
                margin-top: 20px;
                order: 2;
            }
        }
    }
}