@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  10% {
    opacity: 1;
  }

  12% {
    opacity: 0;
  }

  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes reveal {
  0% {
    transform: translate(0,100%);
  }
  100% {
    transform: translate(0,0);
  }
}

.top-baner {
  height: auto;
  width: 100%;
  position: relative;
  padding-bottom: 100px;
  z-index: 0;

  
  @include mq($until: xxl) {
    padding-bottom: 90px;
  }
  @include mq($until: md) {
    padding-bottom: 128px;
  }
  @include mq($until: sm) {
    padding-bottom: 152px;
  }
  @media (max-width: 375px) {
    padding-bottom: 216px;
  }

  &__title{
    position: absolute;
    left: 12vw;
    top: 18.5vw;
    margin: 0;
    text-align: center;
    overflow: hidden;
    line-height: 1;
    font-size: rem(60px);
    font-family: $font-family-extra;
    @include mq($until: xxl) {
      font-size: rem(40px);
    }
    @include mq($until: xl) {
      font-size: rem(35px);
    }
    @include mq($until: lg) {
      font-size: rem(30px);
    }
    @include mq($until: md) {
      font-size: rem(25px);
    }
    @include mq($until: sm) {
      font-size: rem(35px);
      top: 51%;
      transform: translateY(-50%);
    }
    @include mq($until: xs) {
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: rem(30px);
      white-space: nowrap;
    }
    span{
      display: block;
      text-transform: uppercase;
      font-size: inherit;
      font-weight: $fw-regular;
      color: $color-main;
      transform: translate(0,100%);
      &.animate{
        animation: reveal forwards 1.5s cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
      }
      
    }
    
  }

  &__video-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include mq($until: sm) {
      background-image: url("../../images/glosowanie.jpg");
      height: 43vw;
      background-size: cover;
      background-repeat: no-repeat;
      img{
        display: none;
      }
    }
    @include mq($until: xs) {
      height: 50vw;
    }
  }
  &__video {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
 
  }
  &__heading {
    position: absolute;
    padding-right: $padding-xxl;
    width: 30%;
    z-index: 1;
    &.animated {
      animation: fadeOut 23s infinite;
    }
    @include mq($until: xl) {
      padding-right: $padding-xl;
    }
    @include mq($until: lg) {
      padding-right: $padding-lg;
    }
    @include mq($until: md) {
      width: 50%;
    }
    @include mq($until: sm) {
      width: 40%;
      padding-right: $padding-sm;
    }
    @include mq($until: xs) {
      width: 40vw;
      padding-right: $padding-xs;
    }
  }
  &__shape-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    background-image: url('../../images/top-bar22.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    padding-top: 100px;
    padding-bottom: 15px;
    z-index: 1;
    @include mq($until: xxl) {
      padding-top: 80px;
    }
    @include mq($until: xl) {
      padding-top: 95px;
    }
    @media (max-width: 375px) {
      padding-top: 133px;
    }
    &:after {
      content: "";
      bottom: 0;
      transform: translateY(50%);
      left: 0;
      width: 100%;
      height: 15px;
      z-index: -1;
      background-color: $color-extra;
      position: absolute;
      @include mq($until: md) {
        // height: 80px;
        // display: none;
      }
    }
  }
  &__inner-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @include mq($until: sm) {
      flex-direction: column;
    }
  }
  &__header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: sm) {
      order: 2;
    }
  }
  &__map-heading {
    color: $color-font-light;
    text-transform: uppercase;
    font-family: $font-family-extra;
    font-weight: $fw-bold;
    margin: 0;
    font-size: rem(40px);
    @include mq($until: xl) {
      font-size: rem(35px);
    }
    // @include mq($until: lg){
    //   font-size: rem(30px);
    // }
  }
  &__slide-button {
    border: none;
    background-color: transparent;
    margin-left: 10px;
    scroll-behavior: smooth;
    .slide-button-circle {
      fill: $color-main;
      transition: fill 0.3s;
    }
    &:hover .slide-button-circle {
      fill: $color-extra2;
    }
  }
  &__buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: md) {
      flex-direction: column;
      align-items: flex-end;
    }
    @include mq($until: sm) {
      order: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      margin-bottom: 20px;
      width: 100%;
    }
    @media (max-width: 375px) {
      grid-template-columns: 1fr;
    }
  }
  &__link {
    margin-left: 10px;
    @include mq($until: lg) {
      min-width: 250px;
    }
    @include mq($until: md) {
      margin-top: 10px;
      margin-left: 0;
    }
    @include mq($until: sm) {
      margin-top: 0;
      min-width: unset;
      width: 100%;
      padding: 15px;
    }
    &:first-child {
      margin-left: 0;
      @include mq($until: md) {
        margin-top: 0;
      }
    }
  }
  &__bottom-shape {
    position: absolute;
    bottom: 40px;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    width: 101%;
    height: auto;
    @include mq($until: xxl) {
      bottom: 0;
      height: 101%;
    }
    @include mq($until: lg) {
      bottom: 40px;
      height: auto;
    }
    @include mq($until: md) {
      height: 100%;
      width: auto;
      bottom: 50px;
    }
    @include mq($until: md) {
      bottom: 70px;
    }
  }
}
