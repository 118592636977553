.header__submenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: $color-main;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: unset;
  right: 0;
  top: 150%;
  padding: 10px;
  height: auto;
  max-height: 900px;
  min-width: 150px;
  opacity: 1;
  z-index: 9;
  transform: scaleY(1);
  transform-origin: top center;
  transition: opacity .6s ease-in-out, transform .3s ease-in-out;

  &--fullscreen {
    position: static;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    &.header__submenu--hidden{
      display: none;
    }
  }

  &--hidden {
    opacity: 0;
    transform: scaleY(0);
  }

  .header__menuItem {
    width: auto;
    margin: 5px;

    &--fullscreen {
      margin: 0;
    }
  }

  .header__menuLink {
    color: #fff;
    white-space: nowrap;
    position: relative;
    padding-left: 15px;
    font-weight: $fw-regular;
    font-size: rem(20px);
    @include mq($until: md) {
      font-size: rem(24px);
    }
    &:before{
      content: "-";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    &:hover{
      color: $color-extra2;
    }
    &--fullscreen {
      font-size: rem(16px);
      margin-bottom: 5px;
      text-align: center;
    }
  }
}

.header__menuItem:nth-child(1) .header__submenu {
  right: unset;
  left: 0;
}