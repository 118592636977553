.home {
  margin-top: 100px;
  @include mq($until: xl) {
    margin-top: 80px;
  }
  @include mq($until: xs) {
    margin-top: 90px;
  }
}


.widget__info{
  &>svg{
      max-width: 450px;
      margin: 0 auto;
      display: block;
  }
}


.subsite {
  padding-top: 200px;
  padding-bottom: 80px;
  min-height: 100vh;
  @include mq($until: xxl) {
    padding-top: 13vw;
  }
  @include mq($until: lg) {
    padding-top: 14vw;
  }
  @include mq($until: md) {
    padding-top: 160px;
  }
  @include mq($until: xs) {
    padding-top: 140px;
  }

  &--aside{
    padding-top: 60px;
    display: grid;
    grid-template-columns: 1fr 500px;
    grid-gap: 80px;
    @include mq($until: xxl) {
      grid-template-columns: 1fr 400px;
    }
    @include mq($until: xl) {
      // margin-top: 50px;
      grid-template-columns: 1fr 300px;
    }
    @include mq($until: lg) {
      grid-template-columns: 1fr 250px;
    }
    @include mq($until: md) {
      grid-template-columns: 1fr;
    }
    @include mq($until: xs) {
      padding-top: 40px;
    }
  }
  &__content-wrapper{
    width: 100%;
  }
}

.projects-page {
  margin-top: 110px;
  @include mq($until: xl) {
    margin-top: 100px;
  }
  @include mq($until: lg) {
    margin-top: 80px;
  }
}
