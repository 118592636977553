// =========================
// Modules and Variables
// =========================

/**
    mq() takes up to three optional parameters:

    $from: inclusive min-width boundary
    $until: exclusive max-width boundary
    $and: additional custom directives
 */
$mq-breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1500px,
  xxl: 1920px,
);

$color-main: #a62537;
$color-extra: #062538;
$color-extra2: #c99862;
$color-font-light: #ffffff;
$color-font-dark: #062538;

// Font weights
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 700;
$fw-black: 900;

// Font Family
$font-family: "Roboto";
$font-family-extra: "BebasNeue";

// Base Font for HTML
$base-font-size: 16px;

// Paragraph Styles
$paragraph-color: $color-font-dark;
$paragraph-size: 17px;
$paragraph-line-height: 1.5em;
$paragraph-margin: 20px;

// Header Styles
$header-color: $color-font-dark;
$header-size: 40px;
$header-line-height: 1.25em;
$header-margin: $paragraph-margin * 1.2;

// Blockquote Styles
$blockquote-color: $color-font-dark;
$blockquote-size: 23px;
$blockquote-line-height: 1.5em;
$blockquote-margin: $paragraph-margin * 1.5;

// Links
$link-color: $color-main;

// hr
$hr-margin: 30px;

// Type Misc
$font-weight-bold: 600;
$border-color: #ffffff;
