.l-project {
  &__top {
    min-height: 35vw;
    background-color: lightgrey;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    @media (max-width: 74.99em) {
      min-height: 40vw;
    }
    @media (max-width: 61.99em) {
      min-height: 45vw;
    }
    @media (max-width: 47.99em) {
      min-height: 50vw;
    }
    @media (max-width: 35.99em) {
      min-height: 300px;
    }
  }
  &__vote-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__vote-label{
    font-weight: $fw-bold;
    margin-top: 10px;
    font-size: rem(14px);
  }
  &__info-right{
    z-index: 0;
  }
  &__vote-btn{
    @include mq($until: xs) {
      width: 100%;
      min-width: auto;
    }
  }

  &__header {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-image: url('/plugins/ssi/projects/assets/images/project/sub-_h1-bar.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top left;

    img {
      max-width: 400px;
      width: 18vw;
      position: absolute;
      bottom: 30%;
      right: 0;
      @media (max-width: 35.99em) {
        display: none;
      }
    }

    h1 {
      color: #ffffff;
      text-transform: uppercase;
      font-family: "BebasNeue", serif;
      font-size: 4.5rem;
      margin-top: 3rem;
      margin-bottom: 1rem;
      max-width: calc(100% - 20vw);
      line-height: 1.1;
      @media (max-width: 93.74em) {
        font-size: 3rem;
      }
      @media (max-width: 74.99em) {
        font-size: 2.5rem;
      }
      @media (max-width: 35.99em) {
        max-width: unset;
        font-size: 2rem;
      }
    }
  }

  &__info {
    display: grid;
    grid-template-columns: auto 430px;
    grid-gap: 5rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
    @media (max-width: 93.74em) {
      grid-template-columns: 2fr 1fr;
    }
    @media (max-width: 74.99em) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 35.99em) {
      margin-top: 3rem;
    }
  }

  &__map {
    width: 100%;
    min-height: 450px;
    background-color: #d5c0a2;
    @media (max-width: 93.74em) {
      min-height: 400px;
    }
    @media (max-width: 35.99em) {
      min-height: 300px;
    }
  }

  &__hr {
    height: 3px;
    background-color: #a62536;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  &__heading {
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 2.5rem;
    font-size: 3.1rem;
    font-family: "BebasNeue", serif;
    @media (max-width: 93.74em) {
      font-size: 2.4rem;
    }

    &--white {
      color: #ffffff;
    }

    &--red {
      color: #a62536;
    }
  }

  &__box {
    background-color: #1a2a3b;
    position: relative;
    padding: 150px 100px;
    margin-left: calc(8vw - 35px);
    margin-right: calc(8vw - 35px);
    @media (max-width: 93.74em) {
      padding: 5rem 5rem;
      margin-left: 75px;
      margin-right: 75px;
    }
    @media (max-width: 74.99em){
      margin-left: 25px;
      margin-right: 25px;
    }
    @media (max-width: 61.99em) {
      padding: 8rem 3rem 5rem 3rem;
    }
    @media (max-width: 47.99em){
      margin-left: 10px;
      margin-right: 10px;
    }
    @media (max-width: 35.99em) {
      padding: 2rem 1rem;
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  &__frame{
    position: absolute;
    @media (max-width: 35.99em) {
      display: none;
    }
    &--top, &--bottom{
      width: 100%;
      svg{
        height: auto;
        width: 100%;
      }
    }
    &--top{
      top: -10px;
      left: 0;
    }
    &--bottom{
      bottom: -10px;
      left: 0;
    }

    &--left, &--right{
      height: 100%;
      svg{
        height: 100%;
        width: auto;
        max-width: unset;
      }
    }
    &--left{
      top: 0;
      left: -1px;
    }
    &--right{
      top: 0;
      right: -1px;
    }
  }

  &__bottle {
    max-width: 20vw;
    position: absolute;
    left: -2vw;
    top: 100px;
    @media (max-width: 93.74em) {
      top: 3rem;
    }
    @media (max-width: 61.99em) {
      top: -7vw;
    }
    @media (max-width: 47.99em) {
      max-width: 27vw;
      top: -10vw;
    }
    @media (max-width: 35.99em) {
      display: none;
    }
  }

  &__gallery-wrapper {
    h2 {
      text-align: center;
    }
  }

  &__gallery {
    width: 100%;
    padding-left: calc(16vw + 3rem);
    position: relative;
    z-index: 1;

    @media (max-width: 61.99em) {
      padding-left: 0;
    }

    .slick-slide {
      margin: 0 10px;
      @media (max-width: 35.99em) {
        margin: 0;
      }
    }

    .slick-list {
      margin: 0 -10px;
      @media (max-width: 35.99em) {
        margin: 0;
      }
    }
  }

  &__arrow {
    position: absolute;
    top: calc(50% - 29px);
    z-index: 2;
    cursor: pointer;

    @media (max-width: 74.99em) {
      width: 40px;
    }

    svg{
      max-width: 100%;
    }

    &--prev{
      margin-left: -29px;
      @media (max-width: 74.99em) {
        margin-left: -20px;
      }
    }

    &--next {
      right: -29px;
      transform: rotate(180deg);
      @media (max-width: 74.99em) {
        right: -20px;
      }
    }

    .arrow-bg {
      transition: fill .3s ease-in-out;
    }

    &:hover {
      .arrow-bg {
        fill: #1a2a3b;
      }
    }
  }

  &__film-wrapper {
    margin-top: 200px;
    @media (max-width: 93.74em) {
      margin-top: 8rem;
    }
    @media (max-width: 61.99em) {
      margin-top: 4rem;
    }

    h2 {
      text-align: center;
    }
  }

  &__film {
    iframe {
      height: 35vw;
      @media (max-width: 35.99em) {
        height: 60vw;
      }
    }
  }

  &__share {
    margin-top: 10rem;
    @media (max-width: 74.99em) {
      margin-top: 6rem;
    }

    h2 {
      margin-bottom: 1rem;
    }
  }

  &__description {
    margin-top: 4rem;
    margin-bottom: 5rem;
    h2,h3,h4,h5,h6{
      margin-top: 2rem;
    }
  }

  //&__footer {
  //  position: relative;
  //  margin-top: 10rem;
  //  @media (max-width: 35.99em) {
  //    margin-top: 5rem;
  //  }
  //  h2{
  //    max-width: 70%;
  //    @media (max-width: 35.99em) {
  //      font-size: 2rem;
  //    }
  //  }
  //}
  //
  //&__bottles {
  //  position: absolute;
  //  bottom: 9vw;
  //  right: 14vw;
  //  max-width: 15vw;
  //}
  &__info-left-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
}

.c-grid-view {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;

  @media (max-width: 35.99em) {
    grid-template-columns: 1fr;
  }

  &__logo {
    grid-row: 1/3;
    max-height: 150px;
    width: auto;
    @media (max-width: 61.99em) {
      max-height: 125px;
    }
  }
}

a.h-with-icon:hover p {
  color: #a62536;
}

.h-with-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    margin-right: 1rem;
    max-width: 59px;
    height: auto;
    @media (max-width: 93.74em) {
      max-width: 45px;
    }
  }

  p {
    margin: 0;
    font-weight: 500;
    color: #1a2a3b;
    transition: color;
    word-break: break-all;
  }

  &--address, &--localization {
    grid-column: 1;
    p {
      font-size: 1.3rem;
      line-height: 1.3;
      @media (max-width: 93.74em) {
        font-size: 1rem;
      }
    }
  }

  &--address{
    @media (max-width: 35.99em) {
      grid-row: 3;
    }
  }

  &--localization{
    @media (max-width: 35.99em) {
      grid-row: 1;
    }
  }

  &--grand {
    @media (max-width: 35.99em) {
      grid-row: 2;
    }
    span {
      text-transform: uppercase;
      font-family: "BebasNeue", serif;
      font-size: 2.5rem;
      font-weight: 600;
      @media (max-width: 93.74em) {
        font-size: 2rem;
      }

      &:last-of-type {
        margin-left: 0.2rem;
        color: #a62536;
      }
    }
  }
}