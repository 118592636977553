.news {
  display: grid;
  grid-template-columns: 1fr 500px;
  grid-column-gap: 50px;
  grid-row-gap: 40px;
  @include mq($until: xl) {
    grid-template-columns: 1fr 400px;
  }
  @include mq($until: md) {
    grid-template-columns: 100%;
  }
  &__date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    img {
      margin-right: 5px;
    }
  }
  &__category {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
    p {
      font-weight: 600;
      margin-right: 5px;
    }
  }
  &__categories {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    a,
    span {
      &::after {
        content: "|";
        margin-left: 10px;
        margin-right: 10px;
      }
      &:last-of-type::after {
        display: none;
      }
    }
  }
  &__content {
    margin-bottom: 20px;
    // max-width: 70%;
  }
  &__content-wrapper {
    @include mq($until: md) {
      order: 2;
    }
    .news__date {
      @include mq($until: md) {
        display: none;
      }
    }
    .news__category {
      @include mq($until: md) {
        display: none;
      }
    }
  }
  &__media-wrapper {
    @include mq($until: md) {
      order: 1;
    }
    .news__date {
      display: none;
      @include mq($until: md) {
        display: flex;
      }
    }
    .news__category {
      display: none;
      @include mq($until: md) {
        display: flex;
      }
    }
  }
  &__image {
    margin-bottom: 30px;
  }
  .gallery {
    margin-top: 60px;
    margin-bottom: 30px;
  }

  &__main-photo {
    margin-bottom: 15px;
  }
  &__slider-link {
    outline: none;
    padding: 10px;
  }
  &__main-photo-image {
    border-radius: 5px;
    // @include mq($until: md) {
    //   height: 300px;
    // }
  }

  &__other-images-wrapper {
    position: relative;
    margin-bottom: 15px;
  }

  &__nav-slider {
    width: 100%;
    @include mq($until: xl) {
      width: 400px;
    }

    @include mq($until: md) {
      width: 100%;
    }
  }

  &__nav-slider-image-wrapper {
    width: calc(500px / 3);
    height: calc(500px / 3);
    padding: 10px;
    outline: none;
    @include mq($until: xl) {
      width: calc(400px / 3);
      height: calc(400px / 3);
    }
    @include mq($until: md) {
      width: calc(100% / 3);
      height: 200px;
    }
    @include mq($until: xs) {
      width: calc(100% / 2);
      height: 150px;
    }
  }
  &__nav-slider-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    border: 4px solid transparent;
    transition: border-color 0.2s;
    border-radius: 5px;
  }
  &__video {
    max-width: 100%;
    margin-bottom: 15px;
    iframe {
      width: 100% !important;
      @include mq($until: md) {
        max-width: 100%;
      }
      @include mq($until: xs) {
        height: 52vw;
      }
    }
  }
  &__arrow {
    position: absolute;
    top: calc(50% - 29px);
    z-index: 2;
    cursor: pointer;

    @media (max-width: 74.99em) {
      width: 40px;
    }

    @include mq($until: md) {
      width: auto;
    }
    @include mq($until: sm) {
      width: 45px;
    }

    svg {
      max-width: 100%;
    }

    &--prev {
      margin-left: -29px;
      @media (max-width: 74.99em) {
        margin-left: -20px;
      }
      @include mq($until: md) {
        margin-left: -15px;
      }
    }

    &--next {
      right: -29px;
      transform: rotate(180deg);
      @media (max-width: 74.99em) {
        right: -20px;
      }
      @include mq($until: md) {
        right: -15px;
      }
    }

    .arrow-bg {
      transition: fill 0.3s ease-in-out;
    }

    &:hover {
      .arrow-bg {
        fill: #1a2a3b;
      }
    }
  }
}
