.my-account {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 50px;
    margin-top: 50px;
    @include mq($until: xl) {
        grid-template-columns: repeat(5, 1fr);
    }
    @include mq($until: lg) {
        grid-gap: 30px;
    }
    @include mq($until: md) {
        grid-template-columns: repeat(3, 1fr);
    }
    @include mq($until: sm) {
        grid-gap: 15px;
        margin-top: 30px;
    }
    @include mq($until: xs) {
        grid-template-columns: 1fr 1fr;
    }

    &__box {
        display: flex;
        background-color: $color-main;
        align-items: center;
        justify-content: center;
        min-height: 10vw;
        transition: background-color .3s ease-in-out;
        padding: 20px;
        text-align: center;
        line-height: 1.5;
        flex-direction: column;

        @include mq($until: xl) {
            min-height: 13vw;
        }
        @include mq($until: md) {
            min-height: 15vw;
        }

        span {
            color: $color-font-light;
            font-weight: $fw-bold;
            font-size: rem(18px);
        }

        img {
            height: 45px;
          margin-bottom: 15px;
        }

        &:hover {
            background-color: $color-extra;
            color: $color-font-light;
        }
    }
}