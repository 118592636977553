.user-account {
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq($until: md) {
    flex-direction: column;
  }
  &__item {
    display: block;
    min-width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 50px;
    margin-right: 0 !important;
    @include mq($until: xxl) {
      margin-left: 30px;
    }
    @include mq($until: xl) {
      margin-left: 20px;
    }
    @include mq($until: lg) {
      margin-left: 15px;
    }
    @include mq($until: md) {
      margin: 25px 0 0 0;
    }
    &:first-of-type {
      margin-right: 10px;
    }
    font-size: rem(12px);
    svg {
      margin-right: 8px;
      transition: fill 0.3s ease-in-out;
      fill: #fff;
      width: 32px;
      @include mq($until: xxl) {
        width: 30px;
      }
      @include mq($until: lg) {
        width: 28px;
      }
      @include mq($until: md) {
        width: 26px;
      }
    }
  }
}
