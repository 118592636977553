.subsite-footer {
  position: relative;
  margin-top: 60px;
  @include mq($until: md) {
    margin-top: 40px;
  }
  &__heading {
    position: absolute;
    top: 5vw;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    font-size: rem(50px);
    font-family: $font-family-extra;
    max-width: 70vw;
    z-index: 1;
    @include mq($until: xl) {
      font-size: rem(40px);
    }
    @include mq($until: md) {
      top: 2vw;
      max-width: 65vw;
    }
    @include mq($until: sm) {
      font-size: rem(30px);
    }
    @include mq($until: xs) {
      top: 0;
      font-size: rem(25px);
    }
    &--white {
      color: $color-font-light;
    }
    &--red {
      color: $color-main;
    }
  }
  &__bg-foot {
    width: 100%;
    display: block;
  }
  &__bottles {
    position: absolute;
    bottom: 9vw;
    right: 7vw;
    max-width: 27vw;
  }
}
