.modal {
  overflow: hidden;
  &__body {
    position: relative;
    padding: 0;
    padding-top: 50px;
  }
  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: $color-extra !important;
    border-radius: 100%;
    opacity: 1;
    color: $color-font-light;
    font-size: 30px;
    text-shadow: none;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s;
    &:hover {
      background-color: $color-main !important;
      color: $color-font-light;
      opacity: 1 !important;
    }
  }
  &__button{
    min-width: auto;
    @include mq($until: md) {
     width: 100%;
    }
  }
}
