.age-conform {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  &.active {
    display: flex;
  }
  &__container {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-gap: 30px;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    margin: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    @include mq($until: sm) {
      grid-gap: 15px;
    }
  }
  &__logos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    // flex-wrap: wrap;
    max-width: 100%;
    img {
      @include mq($until: sm) {
        width: 100%;
      }
    }
  }
  &__heading {
    font-family: $font-family-extra;
    font-weight: $fw-regular;
    color: $color-main;
    text-align: center;
    font-size: rem(30px);
    letter-spacing: 0.4rem;
    margin: 20px 0;
  }
  &__text-box {
    padding: 20px 0;
    display: grid;
    justify-items: center;
    grid-gap: 120px;
    @include mq($until: md) {
      grid-gap: 100px;
    }
    @include mq($until: sm) {
      grid-gap: 30px;
    }
    p {
      text-align: center;
      margin: 0;
      &:last-child {
        font-size: rem(13px);
        max-width: 43rem;
      }
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: sm) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    button {
      margin-right: 15px;
      @include mq($until: sm) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
        min-width: unset;
      }
      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}
