.footer {
  background-color: #0d2638;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  @include mq($until: sm) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  &__logo {
    width: 120px;
    min-height: 90px;

    position: relative;
    @include mq($until: lg) {
      min-width: 100px;
    }
    img {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      left: 0;
    }
  }
  &__menuList {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    flex-wrap: wrap;
    padding: 0 40px;
    @include mq($until: lg) {
      padding: 0;
    }
    @include mq($until: sm) {
      justify-content: space-between;
    }
  }
  &__menuItem {
    margin-left: 50px;
    margin-top: 0;
    padding: 0;
    @include mq($until: xl) {
      margin-left: 40px;
    }
    @include mq($until: lg) {
      margin-left: 30px;
    }
    @include mq($until: md) {
      margin-top: 20px;
    }
    @include mq($until: sm) {
      margin-left: 10px;
    }

    &:first-child {
      margin-left: 0;
    }
    &::before {
      display: none;
    }
  }
  &__menuLink {
    color: $color-font-light;
    text-transform: uppercase;
    font-weight: $fw-semi-bold;
    font-family: $font-family-extra;
    line-height: rem(35px);
    font-size: rem(26px);
    @include mq($until: xl) {
      font-size: rem(22px);
    }
    @include mq($until: lg) {
      font-size: rem(20px);
    }
    @include mq($until: md) {
      font-size: rem(28px);
    }
    &:hover {
      color: $color-main;
    }
  }
  &__media {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    @include mq($until: sm) {
      margin-top: 20px;
    }
  }

  &__media-item {
    margin-left: 10px;
    margin-top: 0;
    padding: 0;
    @include mq($until: lg) {
      margin-left: 5px;
    }
    @include mq($until: md) {
      margin-top: 20px;
    }
    @include mq($until: sm) {
      margin-left: 10px;
    }
    &:first-child {
      margin-left: 0;
    }
    &:before {
      display: none;
    }
  }

  &__media-link {
    svg {
      fill: $color-font-light;
      @include mq($until: xl) {
        width: 35px;
      }
      @include mq($until: lg) {
        width: 30px;
      }
      @include mq($until: sm) {
        width: 40px;
      }
      &:hover {
        fill: $color-main;
      }
    }
  }
}

.footer-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  @include mq($until: xs) {
    flex-wrap: wrap;
    justify-content: center;
  }
  &__rodo {
    color: #737373;
    &:hover {
      color: $color-main;
    }
    @include mq($until: xs) {
      margin-right: 5px;
    }
  }
  &__realization {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include mq($until: xs) {
      margin-left: 5px;
    }
    span {
      transition: color 0.3s ease-in-out;
      color: #737373;
    }
    &:hover span {
      color: $color-main;
    }
    img {
      margin-left: 15px;
    }
  }
  &__copyright {
    color: #737373;
    @include mq($until: xs) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}
