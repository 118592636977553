.button {
  color: $color-font-light;
  padding: 15px 20px;
  transition-property: color, border, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  background-color: $color-main;
  border-radius: 8px;
  display: inline-block;
  line-height: 1;
  text-align: center;
  border: solid 1px $color-main;
  align-self: flex-start;
  font-weight: $fw-semi-bold;
  text-transform: uppercase;
  font-family: $font-family-extra;
  font-size: rem(22px);
  cursor: pointer;
  min-width: 300px;
  letter-spacing: 1px;

  &:hover {
    background-color: $color-extra2;
    border-color: $color-extra2;
    color: $color-font-light;
  }

  &--secondary {
    background-color: $color-extra2;
    border-color: $color-extra2;

    &:hover {
      background-color: $color-main;
      border-color: $color-main;
    }
  }
  &--hover-blue {
    &:hover {
      background-color: $color-extra;
      border-color: $color-extra;
      color: $color-font-light !important;
    }
  }
}
