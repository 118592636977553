.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: rem(50px);

  :focus {
    outline: none;
  }

  &__line{
    display: grid;
    grid-template-columns: 1fr 80px 1fr;
    justify-content: center;
    align-items: center;
    position: relative;
    &:before{
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      background-color: $color-main;
    }
    &:after{
      content: "";
      display: block;
      height: 1px;
      width: 100%;
      background-color: $color-main;
    }
    span{
      display: block;
      text-align: center;
      width: 100%;
    }
  }


  &__row {
    position: relative;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  &__row-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
    @include mq($until: md) {
      grid-gap: 30px;
    }
    @include mq($until: sm){
      grid-gap: 20px;
    }
    &--modify{
      grid-template-columns: repeat(3, 1fr);
      @include mq($until: sm) {
        grid-template-columns: 1fr;
        .form__row{
          margin: 0;
        }
        .input{
          margin: 0;
        }
      }
    }
  }

  &__heading {
    text-transform: uppercase;
    font-size: rem(30px);
    font-family: $font-family-extra;
    margin-top: 160px;
    border-bottom: 2px solid $color-main;
  }

  &__subheading{
    text-transform: uppercase;
    font-size: rem(27px);
    font-family: $font-family-extra;
    margin-top: 80px;
  }

  &__input {
    margin-top: 5px;
    border: 0;
    padding: 4px 0;
    border-bottom: 1px solid #505052;
    background-color: transparent;
    position: relative;
    font-size: 1.6rem;
    width: 100%;
    &--checkbox {
      margin: 0 5px 0 0;
    }
    &--textarea {
      margin-bottom: -7px;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */

      color: white;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */

      color: white;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */

      color: white;
    }

    &:-moz-placeholder {
      /* Firefox 18- */

      color: white;
    }

    & ~ .form__border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: $color-main;
      transition: 0.3s;
    }

    &:focus ~ .form__border {
      width: 100%;
      transition: 0.3s;
    }

    &:focus ~ .form__label {
      top: -16px;
      font-size: 12px;
      color: $color-main;
      transition: 0.3s;
    }

    &--has-content ~ .form__border {
      width: 100%;
      transition: 0.4s;
    }

    &--has-content ~ .form__label {
      top: -16px;
      font-size: 12px;
      color: $color-main;
      transition: 0.3s;
    }
  }

  &__label {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0px;
    color: $color-font-dark;
    transition: 0.3s;
    z-index: -1;
    letter-spacing: 0.5px;
    display: block;
    margin-bottom: 10px;
  }

  &__label-file {
    margin-bottom: 20px;
    color: $color-font-dark;
    letter-spacing: 0.5px;
  }

  &__rodo {
    font-size: rem(14px);
    width: 100%;
    margin-bottom: rem(30px);
    margin-top: rem(15px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    label,
    input {
      cursor: pointer;
    }
  }

  select {
    background-color: unset;
  }

  option {
    margin: 5px;
  }
}

#topic {
  font-size: rem(18px);
}

.close {
  &:hover {
    cursor: pointer;
  }
}

.alert {
  h4 {
    margin-top: 15px;
    font-size: rem(20px);
  }
}
