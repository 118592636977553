.subsite-top{
    margin-top: 110px;
    position: relative;
    z-index: 0;
    @include mq($until: xl) {
      margin-top: 100px;
    }
    @include mq($until: lg) {
      margin-top: 80px;
    }


    &__bg-wrapper{
      min-height: 35vw;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      @include mq($until: xl) {
        min-height: 40vw;
      }
      @include mq($until: lg) {
        min-height: 45vw;
      }
      @include mq($until: md) {
        min-height: 55vw;
      }
      @include mq($until: sm) {
        min-height: 60vw;
      }
      @include mq($until: xs) {
        min-height: 70vw;
      }

    }

    &__bg{
        width: 100%;
        display: block;
    }

    &__bottom-bar{
        position: absolute;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        background-image: url('../../images/top-bar22.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
        padding-top: 100px;
        padding-bottom: 25px;
        z-index: 1;
        @include mq($until: xs) {
          padding-top: 70px;
          padding-bottom: 15px;
        }
    }
    &__page-title{
        color: $color-font-light;
        text-transform: uppercase;
        font-family: $font-family-extra;
        font-weight: $fw-bold;
        margin: 0;
        font-size: rem(50px);
        padding-right: 31vw;
        @include mq($until: xl) {
          font-size: rem(45px);
        }
        @include mq($until: md) {
          font-size: rem(40px);
        }
        @include mq($until: sm) {
          font-size: rem(35px);
        }
        
    }
    &__image{
        position: absolute;
        width: 400px;
        bottom: 20%;
        right: 0;
        @include mq($until: xxl) {
            width: 300px;
        }
        @include mq($until: xl) {
            width: 250px;
        }
        @include mq($until: md) {
            width: 200px;
        }
        @include mq($until: xs) {
          top: 50%;
          bottom: auto;
          transform: translateY(-75%);
          width: 27vw;
        }
        @media (max-width: 375px) {
            width: 100px;
        }
    }
}