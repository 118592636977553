.home-news {
  padding-top: 120px;
  padding-bottom: 120px;
  &__wrapper {
    display: grid;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 60px 60px 100px 60px;
    @include mq($until: xl) {
      padding: 40px 40px 80px 40px;
    }
    @include mq($until: lg) {
      padding: 50px 50px 90px 50px;
    }
    @include mq($until: md) {
      padding: 50px;
    }
    @include mq($until: sm) {
      padding: 40px;
    }
    @include mq($until: xs) {
      padding: 30px;
    }
  }
  &__top-left-line {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 10px;
      clip-path: polygon(
        0% 0%,
        22% 50%,
        37% 0%,
        55% 50%,
        75% 0%,
        100% 50%,
        100% 100%,
        75% 50%,
        55% 100%,
        37% 50%,
        22% 100%,
        0% 50%
      );
      background-color: $color-extra;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 10px;
      height: 100%;
      clip-path: polygon(
        0% 0%,
        50% 22%,
        0% 37%,
        50% 55%,
        0% 75%,
        0% 100%,
        50% 100%,
        50% 75%,
        100% 55%,
        50% 37%,
        100% 22%,
        50% 0%
      );
      background-color: $color-extra;
    }
  }
  &__bottom-right-line {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 100%;
      clip-path: polygon(
        50% 0%,
        50% 22%,
        0% 37%,
        50% 55%,
        0% 75%,
        50% 100%,
        100% 100%,
        50% 75%,
        100% 55%,
        50% 37%,
        100% 22%,
        100% 0%
      );
      background-color: $color-extra;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 10px;
      clip-path: polygon(
        0% 50%,
        22% 50%,
        37% 0%,
        55% 50%,
        75% 0%,
        100% 50%,
        100% 100%,
        75% 50%,
        55% 100%,
        37% 50%,
        22% 100%,
        0% 100%
      );
      background-color: $color-extra;
    }
  }
  &__logo {
    position: absolute;
    left: 100%;
    bottom: 100%;
    transform: translate(-50%, 50%);
    width: 150px;
    @include mq($until: lg) {
      width: 100px;
    }
    @include mq($until: md) {
      width: 80px;
      transform: translate(-70%, 70%);
    }
    @include mq($until: sm) {
      transform: translate(-80%, 70%);
    }
  }
  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    @include mq($until: xs) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }
  }
  &__heading {
    color: $color-extra;
    text-transform: uppercase;
    font-family: $font-family-extra;
    margin-bottom: 0;
    font-size: rem(40px);
  }
  &__more-link {
    color: $color-main;
    margin-left: 40px;
    font-weight: $fw-semi-bold;
    @include mq($until: xs) {
      margin-left: 0;
    }
    &:hover {
      color: $color-extra;
    }
  }
  &__news-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    @include mq($until: xl) {
      grid-gap: 20px;
      grid-template-columns: 20vw 1fr 1fr;
    }
    @include mq($until: lg) {
      grid-template-columns: 20vw 1fr 1fr;
    }
    @include mq($until: md) {
      position: relative;
      grid-gap: 35px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-template-areas:
        "news1 news2"
        "img news3";
    }
    @include mq($until: sm) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 300px;
      grid-template-areas:
        "news1"
        "news2"
        "news3"
        "img";
      grid-gap: 25px;
    }
    @include mq($until: xs) {
      grid-template-rows: 1fr 1fr 1fr 200px;
    }
  }
  &__item {
    border-radius: 10px;
    overflow: hidden;
    display: grid;
    grid-template-rows: 200px auto;
    width: 100%;
    &:nth-child(odd) {
      transform: translateY(40px);
      @include mq($until: md) {
        transform: none;
      }
    }
    &:hover {
      .home-news__content-wrapper {
        background-color: $color-main;
      }
    }

    @include mq($until: xl) {
      &:last-child {
        display: none;
      }
    }

    @include mq($until: md) {
      grid-template-columns: 1fr;
      grid-template-rows: 220px 1fr;
      grid-area: news1;
      &:nth-child(2) {
        grid-area: news1;
      }
      &:nth-child(3) {
        grid-area: news2;
      }
      &:last-child {
        display: grid;
        grid-area: news3;
      }
    }

    @include mq($until: sm) {
      grid-template-rows: 1fr;
      grid-template-columns: 180px 1fr;
    }

    @include mq($until: xs) {
      grid-template-rows: 180px 1fr;
      grid-template-columns: 1fr;
    }

    &--decorator {
      display: block;
      position: relative;
      overflow: visible;
      border-radius: 0px;
      @include mq($until: lg) {
        // display: none;
      }
      @include mq($until: md) {
        grid-area: img;
      }
      img {
        position: absolute;
        top: 0;
        right: 0;
        max-width: none;
        max-width: 140%;
        max-height: 160%;
        @include mq($until: xxl) {
          max-width: 150%;
        }
        @include mq($until: md) {
          max-width: 135%;
        }
        @include mq($until: sm) {
          right: auto;
          left: calc(50% - 357px / 2);
          transform: none !important;
        }
        @include mq($until: xs) {
          left: calc(50% - 238px / 2);
        }
        // @media (max-width: 386px){
        //   left: 10vw;
        // }
      }
    }
  }
  &__image-wrapper {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  &__content-wrapper {
    padding: 20px 20px 40px 20px;
    background-color: $color-extra;
    transition: background-color 0.2s;
  }
  &__news-title {
    font-family: $font-family-extra;
    font-weight: $fw-regular;
    margin-bottom: 20px;
    color: $color-font-light;
  }
  &__excerpt {
    color: $color-font-light;
  }
}
