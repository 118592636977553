.media-baner{
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: center;
    align-items: flex-start;
    margin-top: 120px;
    padding-top: 30px;
    padding-bottom: 30px;
    cursor: pointer;
    background-color: $color-extra;
    @include mq($until: sm) {
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 80px;
    }
    @include mq($until: xs) {
        padding-top: 15px;
        padding-bottom: 15px;
        margin-top: 40px;
    }
    &__content-side{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        padding-right: 30px;
        @include mq($until: md) {
            padding-right: 20px;
        }
        @include mq($until: xs) {
            padding-right: 10px;
        }
    }
    &__upper-text{
        text-align: right;
        font-size: rem(80px);
        color: $color-font-light;
        font-weight: $fw-regular;
        text-transform: uppercase;
        font-family: $font-family-extra;
        letter-spacing: 2px;
        line-height: rem(80px);
        margin-bottom: 0;
        @include mq($until: xxl) {
            font-size: rem(70px);
            line-height: rem(70px);
        }
        @include mq($until: xl) {
            font-size: rem(55px);
            line-height: rem(55px);
        }
        @include mq($until: lg) {
            font-size: rem(50px);
            line-height: rem(50px);
        }
        @include mq($until: md) {
            font-size: rem(45px);
            line-height: rem(45px);
            letter-spacing: 1px;
        }
        @include mq($until: sm) {
            font-size: rem(35px);
            line-height: rem(35px);
        }
        @include mq($until: xs) {
            font-size: rem(25px);
            line-height: rem(25px);
        }
    }
    &__lower-text{
        text-align: right;
        color: #afafaf;
        font-size: rem(18px);
        @include mq($until: sm) {
            font-size: rem(16px);
        }
        @include mq($until: xs) {
            font-size: rem(12px);
        }
    }
    &__img{
        max-width: 120px;
        @include mq($until: xl) {
            max-width: 90px;
        }
        @include mq($until: md) {
            max-width: 70px;
        }
        @include mq($until: sm) {
            max-width: 50px;
        }
        @include mq($until: xs) {
            max-width: 40px;
        }
    }
}