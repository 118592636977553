.proposal-list {
  margin-top: 80px;
  width: 100%;
  &__header {
    font-size: rem(24px);
    font-weight: $fw-bold;
  }
  &__items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  &__item {
    // padding: 10px 20px 10px 0;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    width: 100%;
    &:before {
      display: none;
    }
    &--head {
      border-bottom: 1px solid #d2d2d2;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr;
      @include mq($until: xs) {
        display: none;
      }
    }
  }
  &__head-label {
    text-align: center;
    padding: 10px;
    white-space: nowrap;
    &:first-child {
      text-align: left;
    }
  }
  &__label {
    display: none;
    font-size: rem(22px);
    font-weight: $fw-regular;
    line-height: inherit;
    margin-right: 10px;
    @include mq($until: xs) {
      display: inline-block;
    }
  }
  &__link {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    @include mq($until: xs) {
      grid-template-columns: 1fr;
      border-bottom: 1px solid $color-main;
    }

    &:hover {
      .proposal-list__button {
        border: solid 1px $color-extra2;
        background-color: $color-extra2;
      }
    }
    &--not-allowed {
      cursor: no-drop;
      pointer-events: none;
      .proposal-list__button {
        border: solid 1px #c4c4c4;
        background-color: #c4c4c4;
        &:hover {
          border: solid 1px #c4c4c4;
          background-color: #c4c4c4;
        }
      }
    }
  }
  &__title {
    font-size: rem(18px);
    font-weight: $fw-bold;
    text-align: left;
    padding: 10px;
    @include mq($until: xs) {
      padding-left: 0;
      font-size: rem(20px);
    }
  }
  &__status {
    font-size: rem(16px);
    text-align: center;
    padding: 10px;
    @include mq($until: xs) {
      text-align: left;
      padding-left: 0;
      font-size: rem(20px);
      font-weight: $fw-bold;
    }
  }
  &__date {
    font-size: rem(16px);
    text-align: center;
    padding: 10px;
    white-space: nowrap;
    @include mq($until: xs) {
      text-align: left;
      padding-left: 0;
      font-size: rem(20px);
      font-weight: $fw-bold;
    }
  }
  &__button {
    font-size: rem(14px);
    min-width: auto;
    padding: 7px 20px;
    width: min-content;
    margin-left: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    @include mq($until: xs) {
      margin-left: 0;
      font-size: rem(16px);
      margin-bottom: 20px;
    }
  }
}
