.sign-in-page {
  display: grid;
  grid-template-columns: 2fr 3fr;
  justify-content: center;
  align-items: flex-start;
  @include mq($until: lg) {
    grid-template-columns: 1fr;
  }

  &__login {
    padding-right: 5vw;
    position: relative;
    @include mq($until: lg) {
      padding-right: 0;
      padding-bottom: 10vw;
    }
  }
  &__register {
    padding-left: 5vw;
    position: relative;
    @include mq($until: lg) {
      padding-left: 0;
      padding-top: 10vw;
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 1px;
      background-color: $color-main;
      height: 100%;
      @include mq($until: lg) {
        width: 100%;
        height: 1px;
      }
    }
  }
}
