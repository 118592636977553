.page-title {
  color: $color-main;
  text-transform: uppercase;
  font-weight: $fw-semi-bold;
  font-family: $font-family-extra;
  font-size: rem(40px);
  margin-bottom: 40px;
  margin-top: 0;

  @include mq($until: sm) {
    margin-bottom: 30px;
  }
  &__wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 40px 0;
    @include mq($until: sm) {
      margin: 30px 0;
    }
    .page-title{
      margin: 0;
      line-height: rem(40px);
    }
  }
  &__back{
    margin-right: 40px;
    text-transform: uppercase;
    font-weight: $fw-semi-bold;
  }
}
