.projects-list{
  margin-top: 100px;
  margin-bottom: 100px;
}
.rt {
  &__header, &__row {
    width: 100%;
    display: grid;
    grid-template-columns: 2rem 2fr 2fr 10rem 7rem 7rem 7rem;
    grid-gap: 1rem;
    padding: 1rem;
    @include mq($until: sm) {
      grid-template-columns: 1fr;
    }

    span {
      font-size: 0.875rem;
      color: #000;
      @media (max-width: 74.99em) {
        font-size: 0.8125rem;
      }
      @include mq($until: sm) {
        font-size: 1rem;
      }
    }
  }

  &__header {
    margin-bottom: 0;
    background: #d6c1a3;
    @include mq($until: sm) {
      display: none;
    }
    span{
      font-weight: 600;
      &:first-child{
        font-size: 1.2rem;
        font-weight: $fw-bold;        
      }
    }
  }

  &__item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:first-child{
      span{
        font-size: 1.3rem;
        font-weight: $fw-bold;
        @include mq($until: sm) {
          font-size: 1.5rem;
        }
      }

    }
    button{
      width: 100%;
      min-width: auto;
    }
    @include mq($until: sm) {
      display: grid;
      grid-template-columns: 120px 1fr;
      align-items: center;
      &::before {
        content: attr(data-column);
        font-weight: 400;
        font-size: 1rem;
        color: $color-main;
      }
      &:first-child{
        &::before {
          font-size: 1.5rem;
          font-weight: $fw-bold;
        }       
      }
    }

    &--wolne, &--status {
      opacity: 1;
    }
    &:first-child{
      font-size: 2rem;
      font-weight: $fw-bold;
    }
    &--voting{      
      @include mq($until: sm) {
        &:last-child{
          grid-template-columns: 1fr;
          &:before{
            display: none;
          }
        }
      }
    }
  }

  &__row {
    &--even {
      background-color: #f9f9f9;
    }

    &:hover {
      background-color: #062538;

      span {
        color: #fff;
      }
    }
  }

  .status {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;

    &--wolne {
      background-color: #8dc63f;
    }

    &--zarezerwowane {
      background-color: darkorange;
    }

    &--sprzedane {
      background-color: #b32227;
    }
  }
}