.files-tree {
  &__file-wrapper {
    max-width: 810px;
    display: flex;
    align-items: center;
    background: lighten($color-main, 43%);
    margin-bottom: 10px;
    margin-left: 25px;
    transition: all 0.5s ease-in-out;
    position: relative;
    padding: 0;
    &:hover {
      background: lighten($color-main, 35%);
      .files-tree__number {
        background: darken($color-main, 10%);
      }
    }
  }
  &__heading {
    color: $color-extra;
    text-transform: uppercase;
    font-weight: $fw-semi-bold;
    font-family: $font-family-extra;
    font-size: rem(35px);
  }
  &__number {
    color: white;
    font-size: rem(27px);
    font-weight: 600;
    background: $color-main;
    width: 55px;
    min-width: 55px;
    text-align: center;
    border-radius: 50%;
    height: 55px;
    padding: rem(14px) 0px;
    line-height: 1;
    margin-left: -25px;
    transition: all 0.5s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mq($until: xs) {
      padding: rem(10px) 0px;
    }
  }
  &__name-link {
    color: #525252;
    font-size: rem(16px);
    font-weight: 300;
    padding-left: 20px;
    padding-right: 80px;
    @include mq($until: xs) {
      padding-left: 10px;
    }
    &:hover {
      color: #000;
    }
  }
  &__cloud-link {
    position: absolute;
    right: 25px;
    display: flex;
    align-items: center;
    @include mq($until: xs) {
      right: 10px;
    }
  }
}
