.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba($color: #fff, $alpha: 0.8);
  height: 100%;
  width: 100%;
  display: none;
  z-index: 2;
  img {
    margin-bottom: 30px;
    width: 48px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.message {
  background-color: #d4edda;
  padding: 15px;
  margin-bottom: 30px;
  &--error {
    background-color: #f8d7da;
  }
  p {
    color: #fff;
  }
}
